<template>
    <section
        v-if="filteredMarkers.length > 0"
        v-bind="$attrs"
        :style="{
            '--background-color': backgroundColor,
            '--text-color': textColor
        }"
        class="map-block"
    >
        <div
            v-if="!!$slots.description"
            class="map-block__description"
        >
            <slot name="description" />
        </div>

        <div class="map-block__map">
            <LazyBaseMap
                map-style="mapbox://styles/brijn/clryzt3gm004p01pa57gd95i0"
                :markers="filteredMarkers"
            />
        </div>
    </section>
</template>

<script setup lang="ts">
import type { ColorPalette } from '~/composables/useColorPalette';
import { useColorPalette } from '~/composables/useColorPalette';

interface Props {
    backgroundColor?: keyof ColorPalette;
    markers: Array<{
        latitude: number;
        longitude: number;
        title: string;
        enabled?: boolean;
        markerDescription?: string;
        markerLink?: Array<{ uri: string }>;
        markerLinkExternal?: string;
    }>;
}

const props = withDefaults(defineProps<Props>(), {
    backgroundColor: () => 'cream',
    markers: () => []
});

const { getTextColor, resolveColor } = useColorPalette();

const backgroundColor = computed(() => resolveColor(props.backgroundColor));
const textColor = computed(() => getTextColor(props.backgroundColor));

const filteredMarkers = computed(() => {
    return props.markers.filter((marker) => {
        if (!marker.enabled || marker.longitude === null || marker.latitude === null) {
            return false;
        }
        // Coordinate format validation
        if (
            isNaN(marker.longitude) ||
            isNaN(marker.latitude) ||
            marker.longitude < -180 ||
            marker.longitude > 180 ||
            marker.latitude < -90 ||
            marker.latitude > 90
        ) {
            return false;
        }

        return marker;
    });
});
</script>

<style lang="less">
.map-block {
    .base-grid(var(--grid-maxWidth-page), false);
    background-color: var(--background-color);
    color: var(--text-color);
    transition: background-color var(--transition-medium);

    position: relative;
    top: calc(var(--banner-height) * -1);
}

.map-block__map {
    grid-column: 1/-1;
    height: 30rem;

    @media @q-lg-min {
        height: 50rem;
    }
}

.map-block__description {
    .typography-quote(false);
    margin-bottom: var(--grid-gutter-margin);
    grid-column: first/right-side-gutter;

    @media @q-lg-min {
        grid-column: first/right-gutter;
    }
}
</style>
